import React, {useRef} from "react"
import {graphql} from "gatsby"

import Layout from '../layouts/default'

import Header from '../components/header'

import ContentElements from '../components/content-elements'

import HeaderContent from '../components/header-content'

export default ({data, pageContext}) => {

  const locale = pageContext.locale || 'de';
  const config = data.allDatoCmsConfig.nodes[0];

  const pageContentRef = useRef(null);

  return (
      <>
        <Layout
            pageContentRef={pageContentRef}
            locale={locale}
            localeLinks={pageContext.localeLinks}
            seo={data.datoCmsSeiten.seoMetaTags}
        >
          {{
            header: (
                <>
                  {(data.datoCmsSeiten.header || []).length > 0 &&
                    <Header
                        locale={locale}
                        localeLinks={pageContext.localeLinks}
                        transparent={true}
                    >
                      <HeaderContent
                          locale={locale}
                          data={data.datoCmsSeiten.header}
                          pageContentRef={pageContentRef}
                      />
                    </Header>
                  }

                  {(data.datoCmsSeiten.header || []).length === 0 &&
                    <Header
                        locale={locale}
                        localeLinks={pageContext.localeLinks}
                        transparent={false}
                    />
                  }
                </>
            ),
            content: (
                <>
                  <ContentElements
                      locale={locale}
                      items={data.datoCmsSeiten.content}
                      config={config}
                  />

                  {(data.datoCmsSeiten.content || []).length === 0 &&
                      <div style={{
                        textAlign: 'center',
                        background: 'rgba(255, 255, 0, .5)',
                        textTransform: 'uppercase',
                        fontSize: '50px',
                        lineHeight: '65px',
                        paddingLeft: '50px',
                        paddingRight: '50px',
                      }} data-datocms-noindex={true}>
                        {locale === 'de' && <>Diese Seite ist leer.</>}
                        {locale !== 'de' && <>This page is empty.</>}
                      </div>
                  }
                </>
            )
          }}
        </Layout>
      </>
  )
}

export const query = graphql`
    query($locale: String!, $id: String!) {
        datoCmsSeiten(id: {eq: $id }) {
            ...cms_header
            ...cms_content
            seoMetaTags {
                tags
            }
        }
        
        allDatoCmsConfig(filter: {locale: {eq: $locale}}) {
            nodes {
                ...config_data
            }
        }
        
        allDatoCmsProdukt(filter: {locale: {eq: $locale}}, limit: 4) {
            nodes {
                id
                slug
                name
                color {
                    hex
                }
                image {
                    alt
                    fluid(imgixParams: {h: "500", auto: "compress", dpr: 1.5, q: 75}) {
                        src
                    }
                }
            }
        }
    }
`
