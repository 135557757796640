import React from "react"

import remoteAssetUrl from '../../lib/remote-asset-url'

import TextHeader from '../text-header'

import styles from './component.module.less'

export default (props) => (
    <>
      {props.image &&
        <img className={styles.img} src={remoteAssetUrl(props.image.fluid.src)} alt={props.image.alt} />
      }

      <TextHeader
          className={styles.imgHeader}
          containerClassName={styles.container}
          center={true}
          header={true}
          title={props.title}
          headline={props.headline}
          headlineType="h1"
      >
        {props.children}
      </TextHeader>
    </>
)
