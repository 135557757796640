import React from "react"

import styles from "./component.module.less"
import TextDecorator from "../../components/text-decorator"
import Text from "../../components/text"


export default props => {

  return (
    <>
      <div
        className={styles.container}
        style={{
          backgroundImage: props.image ? `url(${props.image.fluid.src})` : null,
          backgroundSize: "cover",
        }}
      >
        {!props.image && (
          <TextDecorator className={styles.decorator}>
            {props.decorator}
          </TextDecorator>
        )}
        <Text
          title={props.title}
          headline={props.headline}
          headlineType="h1"
          className={styles.text}
          htmlText={props.text}
        />
      </div>
    </>
  )
}
