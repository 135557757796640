import React from 'react';

import ImageHeader from '../../content-elements/image-header';
import MediaHeader from '../../content-elements/media-header';
import TextHeader from '../../content-elements/text-header';
import VideoHeader from "../../content-elements/video-header";
import ImageTextHeader from "../../content-elements/image-text-header";

export default (props) => {
  if (!props.data || props.data.length === 0) {
    return null;
  }

  switch (props.data[0].internal.type) {
    case 'DatoCmsTextHeader':
      return (
          <TextHeader
              center={true}
              locale={props.locale}
              title={props.data[0].title}
              headline={props.data[0].headline}
              pageContentRef={props.pageContentRef}
          >
            {props.data[0].text}
          </TextHeader>
      );
    case 'DatoCmsImageHeader':
      return (
          <ImageHeader
              center={true}
              locale={props.locale}
              title={props.data[0].title}
              headline={props.data[0].headline}
              image={props.data[0].image}
              pageContentRef={props.pageContentRef}
          >
            {props.data[0].text}
          </ImageHeader>
      );
    case 'DatoCmsVideoHeader':
      return (
          <VideoHeader
              locale={props.locale}
              videoSrc={props.data[0].src.url}
              posterSrc={props.data[0].preview ? props.data[0].preview.fluid.src : null}
              title={props.data[0].title}
              headline={props.data[0].headline}
              pageContentRef={props.pageContentRef}
          />
      );
    case 'DatoCmsMediaHeader':
      return (
        <MediaHeader
          decorator={props.data[0].decorator}
          title={props.data[0].title}
          headline={props.data[0].headline}
          text={props.data[0].text}
          image={props.data[0].image}
        />
      )
    case 'DatoCmsImageTextHeader':
      return (
        <ImageTextHeader
          decorator={props.data[0].decorator}
          title={props.data[0].title}
          headline={props.data[0].headline}
          htmlText={props.data[0].text}
          image={props.data[0].image}
          decoratorOffer={props.data[0].decoratorOffer}
          fullWidth={props.data[0].fullWidth}
          product={props.data[0].product}
          textOffer={props.data[0].textOffer}
          textOfferSuffix={props.data[0].textOfferSuffix}
          color={props.data[0].color}
          background={props.data[0].background}
        />
      )
    default:
      return props.children;
  }
}
