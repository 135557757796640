import React from "react"

import ContentContainer from '../../components/content-container'
import Text from  '../../components/text'

import styles from './component.module.less'

export default function(props) {
  return (
      <section className={`${styles.text}${props.className ? ' ' + props.className : ''}`}>
        <ContentContainer className={`${styles.container}${props.containerClassName ? ' ' + props.containerClassName : ''}`}>
          {props.childPosition && props.childPosition === 'before' &&
              <>{props.children}</>
          }

          <Text
              center={props.center}
              header={true}
              dark={true}
              title={props.title}
              headline={props.headline}
              headlineType="h1"
              htmlText={props.htmlText}
          >
            {(!props.childPosition || props.childPosition === 'after') &&
                <>{props.children}</>
            }
          </Text>
        </ContentContainer>
      </section>
  );
}
