import React from "react"
import { graphql, StaticQuery } from "gatsby"

import styles from "./component.module.less"
import TextDecorator from "../../components/text-decorator"
import Text from "../../components/text"
import OfferHeader from "./offer-header"
import remoteAssetUrl from "../../lib/remote-asset-url"

export default props => {
  let containerClass = [styles.container];

  return (
    <StaticQuery
      query={graphql`
        query {
          allFlamelinkProduct {
            nodes {
              id
              name
              prices {
                date
                purchase
                sell
                currency
              }
            }
          }
        }
      `}
      render={data => {
        let unit;
        let price;
        if (
          props.decoratorOffer &&
          props.textOffer &&
          props.product &&
          props.color
        ) {
          let product = data.allFlamelinkProduct.nodes.find(
            f => f.id === props.product.pricedatabaseReferenzId
          )
          price = product.prices.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          )[0]
          if (price.currency === "EUR") {
            unit = "€/" + props.product.unit
          }
          containerClass.push(styles.containerOffer)
        }

        return (
          <div className={containerClass.join(' ')}>
            <TextDecorator className={styles.decorator}>
              {props.decorator}
            </TextDecorator>
            <div className={styles.row} style={{
              backgroundImage: props.background ? 'url(' + remoteAssetUrl(props.background.fluid.src) + ')' : null,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat"
              }}>
              <img
                className={styles.image}
                src={remoteAssetUrl(props.image.fluid.src)}
                alt={props.image.alt}
              />
              <Text
                title={props.title}
                headline={props.headline}
                className={styles.text}
                htmlText={props.htmlText}
                headlineType="h1"
              >
                {props.text}
              </Text>
            </div>
            {props.decoratorOffer &&
              props.textOffer &&
              props.product &&
              props.color && (
                <div className={styles.offer}>
                  <OfferHeader
                    decorator={props.decoratorOffer}
                    text={props.textOffer}
                    textSuffix={props.textOfferSuffix}
                    product={props.product}
                    price={price.sell}
                    unit={unit}
                    alignLeft={!props.fullWidth}
                    background={props.color}
                    fullWidth={props.fullWidth}
                  ></OfferHeader>
                </div>
              )}
          </div>
        )
      }}
    />
  )
}
