import React, { useEffect, useLayoutEffect, useState } from "react"

import TextDecorator from '../../../components/text-decorator'
import styles from './component.module.less'
import ContentContainer from "../../../components/content-container"

export default (props) => {

    let classNames = [styles.container];
    let priceClass = [styles.price];
    let circleClass = [styles.circle];
    let rowClass = [styles.row];
    let decoratorClass = [styles.decorator];
    let textClass = [styles.text];
    const [widthBase, setWidthBase] = useState("");
    const [fullWidth, setFullWidth] = useState(props.fullWidth);

    if (props.containerClass) {
        classNames = [props.containerClass];
    }
    if (props.fullWidth) {
        classNames.push(styles.width);
        priceClass.push(styles.priceWidth);
        circleClass.push(styles.circleWidth);
        rowClass.push(styles.rowWidth);
        decoratorClass.push(styles.decoratorWidth);
        textClass.push(styles.textWidth)
    }
    if (props.background && props.background === "Rot") {
        classNames.push(styles.background);
    }

    if (props.alignLeft && props.alignLeft === true) {
        classNames.push(styles.left);
    }

    function handleResize() {
        if (window.innerWidth <= 780) {
            setWidthBase("50vw");
            setFullWidth(false);
        } else if (window.innerWidth <= 940) {
            setWidthBase("38vw");
            setFullWidth(false);
        } else if (window.innerWidth <= 1200) {
            setWidthBase("28vw");
            if (props.fullWidth) {
                setFullWidth(true);
            }
        } else if (window.innerWidth <= 1280) {
            setWidthBase("23vw");
            if (props.fullWidth) {
                setFullWidth(true);
            }
        } else {
            setWidthBase("18vw");
            if (props.fullWidth) {
                setFullWidth(true);
            }
        }
    }

    useEffect(() => {
        handleResize();
    }, [props.fullWidth]);

    useLayoutEffect(() => {
        window.addEventListener("resize", handleResize);
    });

    return (
        <section>
            <ContentContainer className={classNames.join(' ')}>
                {props.decorator && <TextDecorator className={decoratorClass.join(' ')} color1={"#fff"} color2={(props.background === "Rot") ? "#B4522F" : "#E8C5A1"} widthBase={widthBase}>{props.decorator}</TextDecorator> }
                <div className={rowClass.join(' ')}>
                    <div className={textClass.join(' ')}>
                        <span style={{fontWeight:"100"}}>{props.product.name}</span>{ props.textSuffix && <> {props.textSuffix}</> }
                        {!fullWidth &&
                            <div className={styles.subtitle} dangerouslySetInnerHTML={{__html: props.text}}/>
                        }
                    </div>
                    {props.price &&
                    <>
                        <div className={priceClass.join(' ')}>
                            <span className={styles.number}>{String(Number(props.price).toFixed(2)).split('.').join(',')}</span><span className={styles.unit}>{props.unit}</span>
                            <span className={styles.subtitle} style={{paddingLeft: "1rem"}}>{!fullWidth ? null : props.text}</span>
                        </div>
                        <div className={circleClass.join(' ')} style={{backgroundColor: (props.background === "Rot") ? "#ea8b8b" : "#E8C5A1"}}></div>
                    </>
                    }
                </div>
            </ContentContainer>
        </section>
    )
}
